body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** Flex */
.grow {
  flex-grow: 1 !important;
}

 /** Text Align */
 .text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

/** Text  */
.fs-small {
  font-size: 14px !important
}


.text-white {
  color: #fff !important;
}

.text-error {
  color: #f44336 !important;
}

.text-success {
  color: #4caf50 !important;
}

.italic {
  font-style: italic !important;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center !important;
}

.strikeThrough {
  text-decoration: line-through !important;
}

.italic {
  font-style: italic !important;
}

.fw100 {
  font-weight: 100 !important;
}

.fw200 {
  font-weight: 200 !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.fw900 {
  font-weight: 900 !important;
}

